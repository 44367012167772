import React, { useState, useEffect } from "react";
import Commonheader from "./Commonheader";
import useAxios from "../hooks/useAxios";
import { Select, Tooltip } from "antd";
import "antd/dist/antd.css";
import { CheckOutlined, DeleteOutlined } from "@ant-design/icons";

function Dropdown_master(props) {
  const [loading, sendRequest] = useAxios();
  const [isErrOpen, setisErrOpen] = useState(false);
  const [api_error_message, setapi_error_message] = useState();
  const [dropdown_idList, setdropdown_idList] = useState();
  const [item_data, setitem_data] = useState();
  const [selectedDropdown, setSelectedDropdown] = useState(null);
  const [dropdown_name, setdropdown_name] = useState();
  const [response_message, setresponse_message] = useState();
  const [data_list, setdata_list] = useState();
  const [editedDropdownName, setEditedDropdownName] = useState("");
  const [isEditing, setIsEditing] = useState(false);
  const [isTitleEdit, setisTitleEdit] = useState("A");
  const [editingItem, setEditingItem] = useState(false);
  const [editedItemId, setEditedItemId] = useState(null);
  const [isNewItemFormVisible, setIsNewItemFormVisible] = useState(false);
  const [isEditingData, setIsEditingData] = useState(false);
  const [addNewItem, setddNewItem] = useState(false);
  const [newItemForms, setnewItemForms] = useState(false);
  const [bucketFieldFormIndex, setBucketFieldFormIndex] = useState(null);
  const [view_name, setview_name] = useState();
  const [effetive_designation_id, seteffetive_designation_id] = useState();
  const [collection_list, setcollection_list] = useState();
  const [select_payload, setselect_payload] = useState();
  const [view_id, setview_id] = useState();
  const [db_name, setdb_name] = useState();
  const [db_list, setdb_list] = useState();
  const [bucket_list, setbucket_list] = useState();
  const [collection_name, setcollection_name] = useState();
  const [bucket_name, setbucket_name] = useState("");
  const [event_name, setevent_name] = useState("");
  const [event_location, setevent_location] = useState("");
  const [get_bucketfield, setget_bucketfield] = useState(false);
  const [mongo_combinesearch, setmongo_combinesearch] = useState();
  const [fields_array_payload, setfields_array_payload] = useState();
  const [default_payload, setdefault_payload] = useState();
  const [api_fields_list, setapi_fields_list] = useState([]);
  const [status, setstatus] = useState("A");
  const [fields_array, setfields_array] = useState();
  const [api_fields_payload, setapi_fields_payload] = useState();
  const [query, setQuery] = useState({ combinator: "and", rules: [] });
  const [editsearchKey, seteditsearchKey] = useState();
  const [search_fields, setsearch_fields] = useState();
  const [isEditingTitle, setIsEditingTitle] = useState();
  const [newTitle, setNewTitle] = useState(false);
  const [searchKey, setsearchKey] = useState([
    { searchKey: "", operator: "", searchValue: "", inputType: "string" },
  ]);
  const [tempsearchKey, settempsearchKey] = useState([
    { searchKey: "", operator: "", searchValue: "", inputType: "string" },
  ]);

  useEffect(() => {
    let apitoken = localStorage.getItem("api_token");

    if (!item_data) {
      sendRequest(
        {
          url: `${process.env.REACT_APP_BASE_URL}/get/item_value/data`,
          method: "GET",
          headers: {
            "api-token": apitoken,
          },
        },
        (response) => {
          setitem_data(response);
        }
      );
    }

    if (!bucket_list) {
      sendRequest(
        {
          url: `${process.env.REACT_APP_BASE_URL}/payload/getcollections`,
          method: "POST",
          //data: inputdata,
          headers: {
            "Content-Type": "application/json",
            "api-token": apitoken,
          },
        },
        (response) => {
          setbucket_list(response);
          //setjiraTicket(response);
        }
      );
    }
    if (!dropdown_idList) {
      getdropdownlist();
    }
  }, []);
  const getdropdownlist = () => {
    let apitoken = localStorage.getItem("api_token");
    sendRequest(
      {
        url: `${process.env.REACT_APP_BASE_URL}/get/dropdown/list`,
        method: "GET",
        headers: {
          "api-token": apitoken,
        },
      },
      (response) => {
        setdropdown_idList(response);
      }
    );
  };
  useEffect(() => {
    if (db_name && db_list) {
      console.log("call db name");
      getcollection(db_name);
    }
  }, [db_name, db_list]);
  useEffect(() => {
    if (select_payload == "M") {
      let apitoken = localStorage.getItem("api_token");
      sendRequest(
        {
          url: `${process.env.REACT_APP_BASE_URL}/mongo/list`,
          method: "POST",
          headers: {
            "api-token": apitoken,
          },
        },
        (response) => {
          //setgetfields(response);
          setdb_list(response);
        }
      );
    }
  }, [select_payload]);

  const add_data = () => {
    let apitoken = localStorage.getItem("api_token");

    const form = new FormData();
    form.append("dropdown_name", dropdown_name);

    sendRequest(
      {
        url: `${process.env.REACT_APP_BASE_URL}/save/new_dropdown/data`,
        method: "POST",
        data: form,
        headers: {
          "Content-Type": "multipart/form-data",
          "api-token": apitoken,
        },
      },
      (response) => {
        console.log(response);
        setisErrOpen(true);
        setapi_error_message(response.message);
        setdropdown_name();
        getdropdownlist();
      },
      (error) => {
        console.error("Error in API request", error);
      }
    );
  };

  const handleDropdownClick = (dropdownId, dropdownName) => {
    if (!isEditing) {
      // setSelectedDropdown((prevSelectedDropdown) =>
      //   prevSelectedDropdown === dropdownId ? null : dropdownId
      // );
      setSelectedDropdown(dropdownId);
      setEditedDropdownName(dropdownName);
      setisTitleEdit("A");

      let apitoken = localStorage.getItem("api_token");
      sendRequest(
        {
          url: `${process.env.REACT_APP_BASE_URL}/get/dropdown_item_master/data`,
          method: "GET",
          headers: {
            "api-token": apitoken,
          },
          params: {
            dropdown_id: dropdownId,
          },
        },
        (response) => {
          console.log(response);
          setdata_list(response);
          setIsNewItemFormVisible(false);
          setIsEditingData(false);
          setNewTitle(dropdownName);
          setfieldlist((prevFieldlist) => {
            const newFieldlist = [];
            for (let i = 0; i < response.length; i++) {
              newFieldlist.push({
                fieldname: "",
                BucketField: "",
              });
            }
            return newFieldlist;
          });
        },
        (error) => {
          console.error("Error in API request", error);
        }
      );
    }
  };
  const [divs, setDivs] = useState([
    {
      id: 1,
      action_name: "",
      api_url: "",
      formdata: [{ id: 1, formdatakey: "", formdatavalue: "" }],
      inputType: "formdata",
      formrow: "",
    },
  ]);
  const [fieldlist, setfieldlist] = useState([
    {
      fieldname: "",
      BucketField: "",
    },
  ]);

  const getcollection = (dbName) => {
    setdb_name(dbName);
    const filtered = db_list.filter((item) => item.db_name === dbName);
    setcollection_list(filtered[0].collections);
  };
  console.log("db list->", divs);

  const handleEditItem = (itemId) => {
    setEditingItem(true);
    setEditedItemId(itemId);
  };

  const setsearchKeydata = (e, i) => {
    const list = [...searchKey];
    let value;
    if (list[i]["inputType"]) {
      value = convertToInputType(e.target.value, list[i]["inputType"]);
    } else {
      value = convertToInputType(e.target.value, "string");
    }

    list[i][e.target.name] = value;
    setsearchKey(list);
    console.log("searchKey", searchKey);
  };

  const formatQuerybuild = (query) => {
    if (!query) return {};
    const mongoQuery = {};
    if (query.combinator === "and") {
      if (query.rules && query.rules.length > 0) {
        mongoQuery.$and = query.rules.map(formatQuerybuild);
      }
    } else if (query.combinator === "or") {
      if (query.rules && query.rules.length > 0) {
        mongoQuery.$or = query.rules.map(formatQuerybuild);
      }
    }

    if (query.field && query.operator) {
      const field = query.field;
      const operator = query.operator;
      const value = query.value;

      if (operator === "=") {
        mongoQuery[field] = value;
      } else if (operator === "!=") {
        mongoQuery[field] = { $ne: value };
      } else if (operator === ">") {
        mongoQuery[field] = { $gt: value };
      } else if (operator === "<") {
        mongoQuery[field] = { $lt: value };
      } else if (operator === ">=") {
        mongoQuery[field] = { $gte: value };
      } else if (operator === "<=") {
        mongoQuery[field] = { $lte: value };
      }

      if (operator === "exists") {
        mongoQuery[field] = {
          $exists: value.toLowerCase() == "true" ? true : false,
        };
      }
    }

    return mongoQuery;
  };

  const convertToInputType = (value, inputType) => {
    switch (inputType) {
      case "string":
        return String(value);
      case "float":
        return parseFloat(value);
      case "int":
        return parseInt(value, 10) | 0;
      default:
        return value;
    }
  };

  const handleRemoveSearch = (index) => {
    const list = [...searchKey];
    list.splice(index, 1);
    setsearchKey(list);
  };

  const getFieldList = (istempsearch = 0) => {
    let url = "";
    let form;
    console.log("select_payload", select_payload);
    if (select_payload == "B") {
      url = `${process.env.REACT_APP_BASE_URL}/payload/get/${bucket_name}?limit=1`;
      let searchinput;
      let formattedSearchKey;
      if (istempsearch == 1) {
        searchinput = tempsearchKey;
        formattedSearchKey = searchinput.map((item) => [
          item.searchKey,
          item.operator,
          item.searchValue,
          item.inputType,
        ]);
      } else {
        searchinput = searchKey;
        formattedSearchKey = searchinput.map((item) => [
          item.searchKey,
          item.operator,
          item.searchValue,
          item.inputType,
        ]);
      }
      console.log("searchinput->", formattedSearchKey);
      form = JSON.stringify(formattedSearchKey);
      if (formattedSearchKey[0][0] != "") {
        setmongo_combinesearch(formattedSearchKey);
      } else {
        setmongo_combinesearch("");
      }
    }
    if (select_payload == "M") {
      url = `${process.env.REACT_APP_BASE_URL}/mongo/get/${db_name}/${collection_name}?limit=1`;
      let searchinput;
      console.log("istempsearch->", istempsearch);
      let formattedSearchKey;
      if (istempsearch == 1) {
        searchinput = tempsearchKey;
        formattedSearchKey = searchinput.map((item) => [
          item.searchKey,
          item.operator,
          item.searchValue,
        ]);
      } else {
        searchinput = searchKey;
        formattedSearchKey = searchinput.map((item) => [
          item.searchKey,
          item.operator,
          item.searchValue,
        ]);
      }
      let finalArray;
      if (istempsearch == 1) {
        finalArray = formattedSearchKey;
      }
      console.log("finalArray->", finalArray);
      setmongo_combinesearch(finalArray);
      form = JSON.stringify(finalArray);
    }
    let apitoken = localStorage.getItem("api_token");

    sendRequest(
      {
        url: url,
        method: "POST",
        data: form,
        headers: {
          "Content-Type": "application/json",
          "api-token": apitoken,
        },
      },
      (response) => {
        setget_bucketfield(false);

        var jsonkey = Object.keys(response);

        const childKey = jsonkey[0];
        setfields_array(response[childKey]);
        setfields_array_payload(response[childKey]);

        setdefault_payload(response);
        const childData = response[childKey];

        // Update setapi_fields_list based on the length of the data array
        //setapi_fields_list(Object.keys(childData));

        data_list.forEach((item, index) => {
          const newData = Object.keys(childData);
          if (newData) {
            // Ensure newData is not null or undefined
            setapi_fields_list((prevList) => [...prevList, newData]);
          }
        });

        setapi_fields_payload(Object.keys(childData));
      }
    );
  };

  const handlechangebucketfield = (e, fieldindex, api_index = null) => {
    if (api_index === null) {
      console.log("fieldlist", fieldindex);
      const list = [...fieldlist];
      let prevdata = list[fieldindex]["BucketField"];
      let result;
      if (prevdata) {
        result = prevdata.concat(".", e.target.value);
      } else {
        result = e.target.value;
      }
      list[fieldindex]["BucketField"] = result;
      setfieldlist(list);
    }

    const childData = fields_array[e.target.value];
    if (childData && Object.keys(childData).length > 0) {
      // Check if fields_array is an array before spreading
      if (Array.isArray(fields_array)) {
        const updatedFieldsArray = [...fields_array]; // Create a copy of fields_array
        updatedFieldsArray[fieldindex] = childData; // Update the data at the specified fieldindex
        setfields_array(updatedFieldsArray); // Set the updated fields_array
      }

      const updatedApiFieldsList = Object.keys(childData);
      //setapi_fields_list(updatedApiFieldsList); // Set the updated api_fields_list
      setapi_fields_list((prevList) => {
        const updatedList = [...prevList];
        updatedList[fieldindex] = updatedApiFieldsList;
        return updatedList;
      });
    }
  };

  const handleItemSave = () => {
    setEditingItem(false);
    let _body;
    if (newItemForms) {
      _body = {
        dropdown_id: selectedDropdown,
        dropdown_name: editedDropdownName,
        flag: isTitleEdit, // U = Update A= do not make any changes
        data: newItemForms,
      };
    } else {
      _body = {
        dropdown_id: selectedDropdown,
        dropdown_name: editedDropdownName,
        flag: isTitleEdit, // U = Update A= do not make any changes
      };
    }
    console.log("_body", _body);
    let apitoken = localStorage.getItem("api_token");
    sendRequest(
      {
        url: `${process.env.REACT_APP_BASE_URL}/save/dropdown/item`,
        method: "POST",
        data: _body,
        headers: {
          "Content-Type": "application/json",
          "api-token": apitoken,
        },
      },
      (response) => {
        setisErrOpen(true);
        setapi_error_message(response.message);
        handleDropdownClick(selectedDropdown, editedDropdownName);
      }
    );
  };
  // const handleNewItemInputChange = (e, formIndex) => {
  //   const updatedForms = data_list.map((form, index) =>
  //     index === formIndex ? { ...form, [e.target.name]: e.target.value } : form
  //   );
  //   console.log("updatedForms", updatedForms);
  //   setdata_list(updatedForms);
  //   setnewItemForms(updatedForms);
  // };

  const handleNewItemInputChange = (e, index) => {
    const { name, value } = e.target;

    const updatedForms = data_list.map((form, idx) => {
      if (idx === index) {
        // Parse the value as JSON if it's next_possible_values or mand_fld_for_this_status
        const parsedValue =
          name === "next_possible_values" || name === "mand_fld_for_this_status"
            ? JSON.parse(value)
            : value;

        // Update the specific property of the form at the given index
        return {
          ...form,
          [name]: parsedValue,
        };
      } else {
        // Parse next_possible_values and mand_fld_for_this_status if they exist in the form

        return form;
      }
    });

    // const parsedForm = { ...updatedForms };
    // if (parsedForm.next_possible_values) {
    //   parsedForm.next_possible_values = JSON.parse(
    //     parsedForm.next_possible_values
    //   );
    // }
    // if (parsedForm.mand_fld_for_this_status) {
    //   parsedForm.mand_fld_for_this_status = JSON.parse(
    //     parsedForm.mand_fld_for_this_status
    //   );
    // }

    const newData = updatedForms.map((item) => ({
      ...item,
      next_possible_values: item.next_possible_values
        ? JSON.parse(item.next_possible_values)
        : null,
      mand_fld_for_this_status: item.mand_fld_for_this_status
        ? JSON.parse(item.mand_fld_for_this_status)
        : null,
    }));

    console.log("parsedForm", newData);
    setdata_list(newData); // Update data_list state variable
    setnewItemForms(newData); // Update newItemForms state variable
  };

  const handleAddNewItem = () => {
    const newForm = {
      item_id: 0,
      item_name: "",
      item_value: "",
      mand_fld_for_this_status: "",
      next_possible_values: "",
      status: "",
      added_by: localStorage.getItem("userid"),
    };
    setdata_list((prevForms) => [...prevForms, newForm]);

    const newFieldList = [];
    for (let i = 0; i < data_list.length + 1; i++) {
      // Use dataList.length + 1 as you're adding a new form
      newFieldList.push({
        fieldname: "",
        BucketField: "",
      });
    }
    setfieldlist(newFieldList);

    if (default_payload) {
      var jsonkey = Object.keys(default_payload);
      const childKey = jsonkey[0];
      const childData = default_payload[childKey];
      data_list.forEach((item, index) => {
        const newData = Object.keys(childData);
        if (newData) {
          // Ensure newData is not null or undefined
          setapi_fields_list((prevList) => [...prevList, newData]);
        }
      });

      const newFieldList = [];
      for (let i = 0; i < data_list.length + 1; i++) {
        // Use dataList.length + 1 as you're adding a new form
        newFieldList.push({
          fieldname: "",
          BucketField: "",
        });
      }
      setfieldlist(newFieldList);
    }

    setIsEditingData(true);
  };

  const updmandfield = (field_data, index_id) => {
    const updatedForm = [...data_list]; // Create a copy of newForm array
    const currentData = updatedForm[index_id].mand_fld_for_this_status || []; // Get the current data or initialize an empty array if it doesn't exist
    updatedForm[index_id].mand_fld_for_this_status = [
      ...currentData,
      field_data,
    ]; // Append the new data to the existing array
    setdata_list(updatedForm);
    console.log("updatedForm", updatedForm); // Log the updated form data for verification
    const fieldlist_data = [...fieldlist];
    fieldlist_data[index_id].BucketField = "";
    console.log("field_data", field_data); // Log the updated form data for verification

    // const fieldlist_data = [...fieldlist];
    // fieldlist_data[index_id].BucketField = "";
    const updatedList = [...api_fields_list];
    updatedList[index_id] = api_fields_payload;

    setapi_fields_list(updatedList);
    console.log("api_fields_list", api_fields_list);
    console.log("fields_array_payload", api_fields_payload);
  };
  const handleDelete = (indexToDelete, arrayindex) => {
    console.log("index data", indexToDelete + "--" + arrayindex);
    const updatedForm = [...data_list];
    let data = updatedForm[arrayindex].mand_fld_for_this_status;
    if (Array.isArray(data)) {
      // Check if the index is valid
      if (indexToDelete >= 0 && indexToDelete < data.length) {
        data.splice(indexToDelete, 1); // Remove one element at the specified index
      } else {
        console.error("Invalid index provided.");
      }
    } else if (typeof data === "string") {
      // Convert data string to array, removing leading and trailing characters
      const dataArray = data.slice(1, -1).split(", ");

      // Check if the index is valid
      if (indexToDelete >= 0 && indexToDelete < dataArray.length) {
        dataArray.splice(indexToDelete, 1); // Remove one element at the specified index
        data = "[" + dataArray.join(", ") + "]"; // Convert array back to string with brackets
      } else {
        console.error("Invalid index provided.");
      }
    } else {
      console.error("Data is not a string.");
    }

    console.log("mand_fld_for_this_status", data);
    const currentData = updatedForm[arrayindex].mand_fld_for_this_status || []; // Get the current data or initialize an empty array if it doesn't exist
    updatedForm[arrayindex].mand_fld_for_this_status = data; // Append the new data to the existing array
    setdata_list(updatedForm);
    //setdata_list(data);
  };
  console.log("api_fields_list", api_fields_list);
  return (
    <div className="container">
      <Commonheader pagetitle={"Dropdown Master"} props={props} />
      {loading && (
        <div className="load-4">
          <div className="ring-1"></div>
        </div>
      )}
      <div
        className={
          get_bucketfield
            ? "modal fade show d-block mymodal"
            : "modal fade bd-example-modal-sm"
        }
        tabIndex="-1"
        role="dialog"
        aria-labelledby="myLargeModalLabel"
        aria-hidden="true"
        id="confirm-box"
      >
        <div className="modal-dialog modal-lg">
          <div className="modal-content cdb-confirm-box col-sm-12">
            <button
              type="button"
              className="close text-right"
              data-dismiss="modal"
              aria-label="Close"
              onClick={(e) => {
                setget_bucketfield(false);
              }}
            >
              <span aria-hidden="true">&times;</span>
            </button>
            <p className="popuperrtext">
              <div className="row">
                <div className="form-group col-md-3">
                  <label htmlFor="inputEmail4">Select Payload </label>
                  <select
                    className="form-control"
                    name="select_payload"
                    disabled={view_id}
                    value={select_payload}
                    onChange={(e) => setselect_payload(e.target.value)}
                  >
                    <option value="">Select</option>
                    <>
                      <option value="B">Bucket</option>
                      <option value="M">Mongo</option>
                    </>
                  </select>
                </div>
                {select_payload == "M" && (
                  <>
                    <div className="form-group col-md-3">
                      <label>DB Name</label>
                      <select
                        className="form-control"
                        name="db_name"
                        value={db_name}
                        disabled={view_id}
                        onChange={(e) => getcollection(e.target.value)}
                      >
                        <option value="">Select DB</option>
                        {db_list &&
                          db_list.map((dbdata) => (
                            <option value={dbdata.db_name}>
                              {dbdata.db_name}
                            </option>
                          ))}
                      </select>
                    </div>
                    <div className="form-group col-md-3">
                      <label>Collection Name</label>
                      <select
                        className="form-control"
                        name="db_name"
                        value={collection_name}
                        disabled={view_id}
                        onChange={(e) => setcollection_name(e.target.value)}
                      >
                        <option value="">Select Collection</option>
                        {collection_list &&
                          collection_list.map((colnm) => (
                            <option value={colnm}>{colnm}</option>
                          ))}
                      </select>
                    </div>
                  </>
                )}
              </div>
              {select_payload == "B" && (
                <>
                  <div className="row">
                    <div className="form-group col-md-3">
                      <label htmlFor="inputEmail4">Bucket</label>
                      <select
                        className="form-control"
                        name="bucket_name"
                        value={bucket_name}
                        disabled={view_id}
                        onChange={(e) => {
                          setbucket_name(e.target.value);
                          setevent_name("");
                          setevent_location("");
                        }}
                      >
                        <option value="">Select Bucket</option>
                        {bucket_list &&
                          bucket_list.map((bucket, index) => (
                            <option value={bucket}>{bucket}</option>
                          ))}
                      </select>
                    </div>
                    <div className="form-group col-md-3">
                      <label htmlFor="inputEmail4">Event Location</label>

                      <input
                        className="form-control"
                        name="event_location"
                        disabled={view_id}
                        value={event_location}
                        onChange={(e) => setevent_location(e.target.value)}
                      />
                    </div>
                    <div className="form-group col-md-3">
                      <label htmlFor="inputEmail4">Event</label>

                      <input
                        className="form-control"
                        name="event_name"
                        disabled={view_id}
                        value={event_name}
                        onChange={(e) => setevent_name(e.target.value)}
                      />
                    </div>
                  </div>
                  <div className="row justify-content-center">
                    <strong>OR Search Specific Payload</strong>
                  </div>
                </>
              )}
              {select_payload && (
                <>
                  <div className="row justify-content-center">
                    {searchKey.map((searchdata, i) => (
                      <>
                        <div className="form-group col-md-3">
                          <label htmlFor="inputEmail4">Key</label>
                          <input
                            className="form-control"
                            name="searchKey"
                            disabled={view_id}
                            value={searchdata.searchKey}
                            onChange={(e) => setsearchKeydata(e, i)}
                          />
                        </div>
                        <div className="form-group col-md-2">
                          <label htmlFor="inputEmail4">Operator</label>
                          <select
                            className="form-control"
                            name="operator"
                            disabled={view_id}
                            onChange={(e) => setsearchKeydata(e, i)}
                            value={searchdata.operator}
                          >
                            <option value="">select</option>
                            <option value="==">==</option>
                            <option value="exists">exists</option>
                            <option value=">">&#62;</option>
                            <option value="<">&#60;</option>
                          </select>
                        </div>
                        <div className="form-group col-md-2">
                          <label htmlFor="inputEmail4">Input Type</label>
                          <select
                            className="form-control"
                            name="inputType"
                            disabled={view_id}
                            value={searchdata.inputType}
                            onChange={(e) => setsearchKeydata(e, i)}
                          >
                            <option value="string">String</option>
                            <option value="int">Int</option>
                            <option value="float">Float</option>
                          </select>
                        </div>
                        <div className="form-group col-md-3">
                          <label htmlFor="inputEmail4">Value</label>
                          <input
                            className="form-control"
                            name="searchValue"
                            disabled={view_id}
                            value={searchdata.searchValue}
                            onChange={(e) => setsearchKeydata(e, i)}
                          />
                        </div>

                        {!view_id && (
                          <div className="btn-box">
                            <div className="form-group col-md-2">
                              {searchKey.length !== 1 && (
                                <button
                                  className="btn btn-danger"
                                  onClick={(e) => {
                                    handleRemoveSearch(i);
                                  }}
                                >
                                  -
                                </button>
                              )}
                            </div>
                          </div>
                        )}
                      </>
                    ))}
                    <button
                      className="btn btn-primary"
                      style={{
                        height: "40px",
                        width: "90px",
                      }}
                      onClick={(e) => {
                        getFieldList();
                      }}
                    >
                      Get Field
                    </button>
                  </div>
                </>
              )}
            </p>
          </div>
        </div>
      </div>
      <div
        className={
          isErrOpen
            ? "modal fade show d-block mymodal"
            : "modal fade bd-example-modal-sm"
        }
        tabIndex="-1"
        role="dialog"
        aria-labelledby="myLargeModalLabel"
        aria-hidden="true"
        id="confirm-box"
      >
        <div className="modal-dialog modal-lg">
          <div className="modal-content cdb-confirm-box col-sm-12">
            <button
              type="button"
              className="close text-right"
              data-dismiss="modal"
              aria-label="Close"
              onClick={() => {
                setisErrOpen(false);
              }}
            >
              <span aria-hidden="true">&times;</span>
            </button>
            <p className="popuperrtext">{api_error_message}</p>
          </div>
        </div>
      </div>
      <div className="d-flex justify-content-center align-items-center text-center mt-4">
        <input
          type="text"
          value={dropdown_name}
          placeholder="New Dropdown"
          onChange={(e) => setdropdown_name(e.target.value)}
          className="form-control mr-2"
          style={{ maxWidth: "200px" }}
        />
        <button
          className="btn btn-primary"
          disabled={!dropdown_name}
          onClick={add_data}
        >
          Add
        </button>
      </div>
      <div className="mt-4">
        <div className="column">
          {dropdown_idList &&
            dropdown_idList.map((item, index) => (
              <div key={item.dropdown_id} className="col-md-4 mb-3">
                <div
                  className="card"
                  style={{ width: "1080px", backgroundColor: "#fff" }}
                >
                  <div className="card" style={{ backgroundColor: "white" }}>
                    <div className="card-body">
                      <div className="d-flex justify-content-between align-items-center">
                        <div>
                          {isEditingTitle == index ? (
                            <input
                              type="text"
                              value={editedDropdownName}
                              onChange={(e) => {
                                setEditedDropdownName(e.target.value);
                                setisTitleEdit("U");
                              }}
                            />
                          ) : (
                            <h5
                              className="card-title"
                              onClick={(e) =>
                                handleDropdownClick(
                                  item.dropdown_id,
                                  item.dropdown_name
                                )
                              }
                            >
                              <a href="javascript:void(0)">
                                {item.dropdown_name}
                              </a>
                            </h5>
                          )}
                        </div>
                      </div>
                      {item.dropdown_id === selectedDropdown && (
                        <div className="form-row">
                          <div className="col-md-12 mb-2">
                            <button
                              className="btn btn-sm btn-primary mt-2 float-right"
                              onClick={(e) => {
                                setget_bucketfield(true);
                              }}
                            >
                              Get Bucket Field
                            </button>
                          </div>
                          {data_list &&
                            data_list.map((responseItem, formIndex) => (
                              <div
                                key={responseItem.item_id}
                                className="form-row mb-2"
                              >
                                <div className="col-md-3">
                                  <label
                                    htmlFor={`itemName${responseItem.item_id}`}
                                  >
                                    Item Name:
                                  </label>
                                  <input
                                    type="text"
                                    id={`itemName${responseItem.item_id}`}
                                    className="form-control"
                                    value={responseItem.item_name}
                                    disabled={
                                      !isEditingData &&
                                      responseItem.item_id != 0
                                    }
                                    name="item_name"
                                    onChange={(e) =>
                                      handleNewItemInputChange(e, formIndex)
                                    }
                                  />
                                </div>
                                <div className="col-md-3">
                                  <label
                                    htmlFor={`itemValue${responseItem.item_id}`}
                                  >
                                    Item Value:
                                  </label>
                                  <input
                                    type="text"
                                    id={`itemValue${responseItem.item_id}`}
                                    className="form-control"
                                    value={responseItem.item_value}
                                    name="item_value"
                                    onChange={(e) =>
                                      handleNewItemInputChange(e, formIndex)
                                    }
                                    disabled={
                                      !isEditingData &&
                                      responseItem.item_id != 0
                                    }
                                  />
                                </div>
                                <div className="col-md-3">
                                  <label
                                    htmlFor={`mandatoryField${responseItem.item_id}`}
                                  >
                                    Mandatory Field:
                                  </label>
                                  <>
                                    <select
                                      className="form-control"
                                      name="BucketField"
                                      onChange={(e) => {
                                        handlechangebucketfield(e, formIndex);
                                      }}
                                      disabled={
                                        !isEditingData &&
                                        responseItem.item_id != 0
                                      }
                                      value=""
                                    >
                                      <option value="">Select API Field</option>
                                      {api_fields_list.length > 0 &&
                                        api_fields_list[
                                          formIndex
                                        ].map((field) => (
                                          <option value={field}>{field}</option>
                                        ))}
                                    </select>

                                    <div>
                                      {responseItem.mand_fld_for_this_status &&
                                      Array.isArray(
                                        responseItem.mand_fld_for_this_status
                                      )
                                        ? responseItem.mand_fld_for_this_status.map(
                                            (row, index_id) => (
                                              <div key={index_id}>
                                                {row != "ul" && (
                                                  <>
                                                    {row}
                                                    <DeleteOutlined
                                                      onClick={() =>
                                                        handleDelete(
                                                          index_id,
                                                          formIndex
                                                        )
                                                      }
                                                      className="float-right"
                                                    />
                                                  </>
                                                )}
                                              </div>
                                            )
                                          )
                                        : typeof responseItem.mand_fld_for_this_status ===
                                          "string"
                                        ? responseItem.mand_fld_for_this_status
                                            .slice(1, -1) // Remove the first and last characters, i.e., [ and ]
                                            .replace(/"/g, "") // Remove all occurrences of double quotes
                                            .split(",") // Split the string by comma
                                            .map((row, index_id) => (
                                              <div key={index_id}>
                                                {row != "ul" && (
                                                  <>
                                                    {row}
                                                    <DeleteOutlined
                                                      onClick={() =>
                                                        handleDelete(
                                                          index_id,
                                                          formIndex
                                                        )
                                                      }
                                                      className="float-right"
                                                    />
                                                  </>
                                                )}
                                              </div>
                                            ))
                                        : null}

                                      {console.log(
                                        "mand_fld_for_this_status",
                                        responseItem.mand_fld_for_this_status
                                      )}
                                      {fieldlist[formIndex].BucketField && (
                                        <>
                                          {fieldlist[formIndex].BucketField}
                                          <CheckOutlined
                                            onClick={() =>
                                              updmandfield(
                                                fieldlist[formIndex]
                                                  .BucketField,
                                                formIndex
                                              )
                                            }
                                            className="float-right"
                                          />
                                        </>
                                      )}
                                    </div>
                                  </>
                                </div>
                                <div className="col-md-3">
                                  <label
                                    htmlFor={`nextPossibleValue${responseItem.item_id}`}
                                  >
                                    Next Possible Value:
                                  </label>

                                  <Select
                                    mode="multiple"
                                    defaultValue={
                                      responseItem.next_possible_values &&
                                      typeof responseItem.next_possible_values ===
                                        "string"
                                        ? responseItem.next_possible_values
                                            .slice(1, -1)
                                            .split(", ")
                                            .map(String)
                                        : []
                                    }
                                    className="form-control"
                                    disabled={
                                      !isEditingData &&
                                      responseItem.item_id != 0
                                    }
                                    onChange={(e) => {
                                      const updatedForms = data_list.map(
                                        (form, index) =>
                                          index === formIndex
                                            ? {
                                                ...form,
                                                next_possible_values: e,
                                              }
                                            : form
                                      );

                                      setdata_list(updatedForms);
                                      setnewItemForms(updatedForms);
                                    }}
                                    options={item_data.map((item) => {
                                      return {
                                        label: item.item_id,
                                        value: item.item_id,
                                      };
                                    })}
                                    maxTagPlaceholder={(omittedValues) => (
                                      <Tooltip
                                        title={omittedValues
                                          .map(({ label }) => label)
                                          .join(", ")}
                                      >
                                        <span>Hover Me</span>
                                      </Tooltip>
                                    )}
                                  />
                                </div>
                                <div className="col-md-3">
                                  <label
                                    htmlFor={`status${responseItem.item_id}`}
                                  >
                                    Status:
                                  </label>
                                  <input
                                    type="text"
                                    id={`status${responseItem.item_id}`}
                                    className="form-control"
                                    value={responseItem.status}
                                    name="status"
                                    disabled={
                                      !isEditingData &&
                                      responseItem.item_id != 0
                                    }
                                    onChange={(e) =>
                                      handleNewItemInputChange(e, formIndex)
                                    }
                                  />
                                </div>
                                <div className="col-md-3">
                                  <label
                                    htmlFor={`addedBy${responseItem.item_id}`}
                                  >
                                    Added By:
                                  </label>
                                  <input
                                    type="text"
                                    id={`addedBy${responseItem.item_id}`}
                                    className="form-control"
                                    value={responseItem.added_by}
                                    name="added_by"
                                    disabled
                                  />
                                </div>

                                {formIndex < data_list.length - 1 && <hr />}
                              </div>
                            ))}

                          <div
                            style={{
                              marginRight: "15px",
                              marginTop: "15px",
                              marginBottom: "30px",
                            }}
                          >
                            <button
                              className="btn btn-sm btn-primary mr-2"
                              onClick={(e) => {
                                handleAddNewItem();
                              }}
                            >
                              Add New Item
                            </button>
                            <button
                              className="btn btn-sm btn-warning mr-2"
                              onClick={(e) => {
                                handleEditItem();
                                setIsEditingTitle(index);
                                setIsEditingData(true);
                              }}
                            >
                              Edit
                            </button>
                            <button
                              className="btn btn-sm btn-danger mr-2"
                              onClick={(e) => {
                                setEditingItem(false);
                                setIsEditingData(false);
                                setIsEditingTitle();
                              }}
                              disabled={!isEditingData}
                            >
                              Cancel
                            </button>
                            <button
                              className="btn btn-sm btn-primary"
                              onClick={(e) => {
                                handleItemSave();
                              }}
                              disabled={!isEditingData}
                            >
                              Save
                            </button>
                          </div>

                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              whiteSpace: "nowrap",
                            }}
                          ></div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            ))}
        </div>
      </div>
    </div>
  );
}
export default Dropdown_master;
