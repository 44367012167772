import React, { useState } from "react";
import axios from "axios";
import jwt_decode from "jwt-decode";
import Cookies from "js-cookie";
import UserExperior from "user-experior-web";

export default function useAxios(props) {
  const [loading, setLoading] = useState(false);

  const sendRequest = async (config, action) => {
    try {
      setLoading(true);
      config.headers["api-token"] = localStorage.getItem("access_token");
      await UseJWTToken(config, action);
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };
  return [loading, sendRequest];
}

async function UseJWTToken(config, action) {
  await istokenexpired(config, action);
}

async function istokenexpired(config, action) {
  const token = localStorage.getItem("access_token");
  const refresh_token = localStorage.getItem("refresh_token");
  console.log("token->", token);
  console.log("refresh_token->", refresh_token);

  if (token) {
    var decoded = jwt_decode(token);
    console.log("decoded->", decoded);
    let currentDate = new Date();

    // JWT exp is in seconds
    if (decoded.exp * 1000 < currentDate.getTime()) {
      console.log("Token expired.");
      return await generate_token(config, action);
    } else {
      console.log("Valid token");
      return await call_api(config, action);
    }
  }
}

async function generate_token(config, action) {
  const formdata = new FormData();

  if (Cookies.get("refresh_token")) {
    var decode_reftoken = jwt_decode(Cookies.get("refresh_token"));
    let currentDate = new Date();

    if (decode_reftoken.exp * 1000 < currentDate.getTime()) {
      Cookies.remove("refresh_token");
      localStorage.clear();
      window.location.href = "/";
    } else {
      formdata.append("refresh_token", Cookies.get("refresh_token"));

      try {
        const response = await fetch(
          process.env.REACT_APP_BASE_URL + "/get/access/token",
          {
            method: "POST",
            body: formdata,
          }
        );

        if (response.ok) {
          const data3 = await response.json();
          localStorage.setItem("access_token", data3.access_token);
          config.headers["api-token"] = data3.access_token;
          return await call_api(config, action);
        } else {
          console.log("Error fetching token:", response);
        }
      } catch (error) {
        console.log(error);
      }
    }
  }
}

async function call_api(config, action) {
  try {
    const response = await axios(config);
    action(response.data);
    if(Cookies.get('parameter_value') !== 'null' && Cookies.get('parameter_value') !== undefined) {
      const ue = new UserExperior();
      let urlName = config.url.split('/');
      urlName = urlName[urlName.length - 1]
          ue.startRecording(Cookies.get('parameter_value'), {
            sessionReplay: { 
                maskAllInputs: true,
                maskInputOptions: {
                    password: true,
                    email: true,
                    tel: true,
                    color: false,
                    date: false,
                    'datetime-local': false,
                    month: false,
                    number: false,
                    range: false,
                    search: false,
                    text: true,
                    time: false,
                    url: false,
                    week: false,
                    textarea: false,
                    select: false,
                }
            }
          });
          ue.setUserIdentifier(`${localStorage.getItem("in_username")}_${process.env.REACT_APP_ENVIRONMENT}`);
          // ue.setUserIdentifier(localStorage.getItem("in_userid"));
          ue.logEvent(urlName, {
            'headers': JSON.stringify(config.headers),
            'method': config.method,
            'url': config.url
          });
          ue.logEvent('response', {
            'headers': JSON.stringify(config.headers),
            'status': config.status
          });
    }
  } catch (error) {
    // Handle errors as needed
  }
}
