import React, { Component } from "react";
export const FilterContext = React.createContext({ isLoaded: "aaaaa" });

export class FilterContextProvider extends React.Component {
  constructor() {
    super();
    this.state = {
      filters: [],
      filterConfig: 1,
      searchText: "",
      sort_htl: ""
    };

  }
  componentDidMount() {
    // console.log(this.state.filters)
  }
  setFilters = (array, filterConfig) => {
    this.setState({
      filters: array,
      filterConfig: filterConfig,
      searchText: ''
    });
  };
  setSearchText = (search_text) => {
    this.setState({
      searchText: search_text,
      filters: [],
      filterConfig: 1
    })
  }
  setSortHTL = (sort_htl) => {
    this.setState({
      sort_htl: sort_htl
    })
  }

  render() {
    const { filters, filterConfig, searchText, sort_htl } = this.state;
    //const {setisLoaded} = this;
    return (
      <>
        <FilterContext.Provider value={{
          filters: filters,
          searchText: searchText,
          filterConfig: filterConfig,
          sort_htl: sort_htl,
          setSortHTL: this.setSortHTL,
          setFilters: this.setFilters,
          setSearchText: this.setSearchText
        }}>
          {this.props.children}
        </FilterContext.Provider>
      </>
    );
  }
}
