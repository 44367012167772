import React, { useState, useEffect } from "react";
import Commonheader from "./Commonheader";
import useAxios from "../hooks/useAxios";

function Unblock_user(props) {
  const [loading, sendRequest] = useAxios();
  const [isErrOpen, setisErrOpen] = useState(false);
  const [api_error_message, setapi_error_message] = useState();
  const [user_id, setuser_id] = useState();
  const unblock_userid = () => {
    let apitoken = localStorage.getItem("api_token");
    sendRequest(
      {
        url:
          `${process.env.REACT_APP_BASE_URL}/unblock/user?username=` + user_id,
        method: "GET",
        headers: {
          "api-token": apitoken,
        },
      },
      (response) => {
        setisErrOpen(true);
        setapi_error_message(response.message);
      }
    );
  };
  return (
    <div className="container">
      <Commonheader pagetitle={"Unblock User"} props={props} />
      {loading && (
        <div className="load-4">
          <div className="ring-1"></div>
        </div>
      )}
      <div
        className={
          isErrOpen
            ? "modal fade show d-block mymodal"
            : "modal fade bd-example-modal-sm"
        }
        tabIndex="-1"
        role="dialog"
        aria-labelledby="myLargeModalLabel"
        aria-hidden="true"
        id="confirm-box"
      >
        <div className="modal-dialog modal-lg">
          <div className="modal-content cdb-confirm-box col-sm-12">
            <button
              type="button"
              className="close text-right"
              data-dismiss="modal"
              aria-label="Close"
              onClick={() => {
                setisErrOpen(false);
                window.location.reload();
              }}
            >
              <span aria-hidden="true">&times;</span>
            </button>
            <p className="popuperrtext">{api_error_message}</p>
          </div>
        </div>
      </div>
      <div className="mt-4">
        <div className="row">
          <div className="col-md-4">
            <input
              placeholder="Enter Id"
              className="form-control"
              onChange={(e) => setuser_id(e.target.value)}
            />
          </div>
          <div className="col-md-2">
            <button
              className="btn btn-primary"
              onClick={() => unblock_userid()}
            >
              Unblock
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Unblock_user;
