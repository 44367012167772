// AT-2018 | AT-2260 | Ankur Makavana | 03/08/2023 | create new screen for transformation
import React, { useState, useEffect } from "react";
import Commonheader from "./Commonheader";
import useAxios from "../hooks/useAxios";
import { EditOutlined } from "@ant-design/icons";

function Transformation_mapping_list(props) {
  const [loading, sendRequest] = useAxios();
  const [mapping_data, setmapping_data] = useState();

  useEffect(() => {
    let apitoken = localStorage.getItem("api_token");
    sendRequest(
      {
        url: `${process.env.REACT_APP_BASE_URL}/get/mapping/data`,
        method: "GET",
        //data: inputdata,
        headers: {
          "api-token": apitoken,
        },
      },
      (response) => {
        setmapping_data(response);
        //setjiraTicket(response);
      }
    );
  }, []);
  return (
    <div className="container">
      <Commonheader pagetitle={"Mapping List"} props={props} />
      {loading && (
        <div className="load-4">
          <div className="ring-1"></div>
        </div>
      )}

      <div className="mt-4">
        <div className="viewname-holder">
          <table className="table">
            <thead>
              <tr>
                <th>ND Event</th>
                <th>Vendor Event</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {mapping_data &&
                mapping_data.map((mapdata) => (
                  <tr>
                    <td>{mapdata.nd_event_name}</td>
                    <td>{mapdata.vendor_event_name}</td>
                    <td>
                      <button
                        type="button"
                        class="btn btn-outline-info"
                        onClick={() =>
                          props.history.push({
                            pathname: "/Transformation_mapping",
                            state: {
                              view_id: mapdata.id,
                            },
                          })
                        }
                      >
                        <EditOutlined />
                      </button>
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}
export default Transformation_mapping_list;
