// src/components/ResultsModal.js
import React from 'react';
import { Modal, Button } from 'react-bootstrap';
import "../assets/css/Element_permissions.css";

const ResultsModal = ({ show, onHide, results }) => {
  return (
    <Modal show={show} onHide={onHide}>
      <Modal.Header closeButton>
        <Modal.Title>Users</Modal.Title>
      </Modal.Header>
      <Modal.Body>
       
        {results.length > 1 ? (
         
             <table className="results-table">
          <thead>
            <tr>
              <th>Name</th>
              <th>Username</th>
            </tr>
          </thead>
          <tbody>
            {results.map((result, index) => (
              <tr key={index}>
                 {/* <p><span className='left'><strong>Name: </strong>{result && result.first_name} </span>&nbsp;&nbsp;<span className='right'><strong>Username: </strong>{result &&  result.username}</span></p>*/}
                
              <td>{result.first_name}</td> 
              <td> {result.username}</td> 
          
               
                
                
              </tr>

              
            ))}
               
            
            
         

          </tbody>
        </table>
        ) : (
          <p>No users found.</p>
        )}
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={onHide}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ResultsModal;
