import React, { useState, useEffect } from 'react';
import "../assets/css/Element_permissions.css";
import useAxios from "../hooks/useAxios";

import "antd/dist/antd.css";
import { CheckOutlined, DeleteOutlined } from "@ant-design/icons";
import loader from "../assets/images/loader.gif";
import Commonheader from "./Commonheader";
import { Select } from 'antd';
import ResultsModal from './ResultsModal'
import { Modal, Button } from 'react-bootstrap';




const App = (props) => {
    const [loading, sendRequest] = useAxios();
  const [selectedApp, setSelectedApp] = useState('');
  const [selectedComponentId, setSelectedComponentId] = useState('');
  const [components, setComponents] = useState([]);
  const [functionalities, setFunctionalities] = useState([]);
  const [elementPermissions, setElementPermissions] = useState({});
  const [isSpin, setisSpin] = useState(false);
 const [selectedFunctionId, setSelectedFunctionId] = useState('');
 const [showResultsModal, setShowResultsModal] = useState(false);
 const [apiResults, setApiResults] = useState({})
 const [showModal, setShowModal] = useState(false); // Modal state
const [modalInfo, setModalInfo] = useState(null); // Modal info state
const [userCount, setUserCount] = useState(null);
const [userNumber, setUserNumber] = useState({})


  useEffect(() => {
    if (selectedApp) {
        
      fetchComponents(selectedApp);
      
    }
  }, [selectedApp]);

  useEffect(() => {
    if (selectedComponentId) {
        
      fetchFunctionalities(selectedComponentId);
     
    }
  }, [selectedComponentId]);

  const form=new FormData();

  const fetchComponents =  (app) => {
    form.append("app_name", app);
    form.append("flag", "fetch_component");

    let apitoken = localStorage.getItem("api_token");
    console.log(apitoken);
   

    setisSpin(true);

    sendRequest(

        
      {
        url: `${process.env.REACT_APP_BASE_URL}/permission/data`,
        method: "POST",
        data:form,
        headers: {
          
          
          "Content-Type": "text/html",
          
          "api-token": apitoken,
        },

     
       
      },
      (response) => {
        setisSpin(false);
       
        setComponents(Array.isArray(response.components) ? response.components : [response.components]);
        console.log(response);
       
      }
    );

  
  };

  const fetchFunctionalities =  (componentId) => {
    try {
      
        setisSpin(true);

      const form2 = new FormData();
      form2.append("flag","fetch_functionality")
      form2.append("component_id",componentId)
   
      
  
    
  
  
      let apitoken = localStorage.getItem("api_token");
     
     
  
      sendRequest(
        {
          url: `${process.env.REACT_APP_BASE_URL}/permission/data`,
          method: "POST",
          data:form2,
          headers: {
            
            
            
            'Content-Type': 'application/json',
            "api-token": apitoken,
          },
  
      
         
        },
        (response) => {
          
         const data =  response;
         setisSpin(false);
          
      
         
          const updatedFunctionalities = data.functionality.map((func) => ({ ...func, isOpen: false }));

      
      const initialPermissions = {};
      updatedFunctionalities.forEach((func) => {
        initialPermissions[func.functionality_id] = {};
        func.element_permission.forEach((element) => {
          initialPermissions[func.functionality_id][element.element_id] = element.permission;
        });
      });
      setElementPermissions(initialPermissions);
     
      setFunctionalities(updatedFunctionalities);
   



   

      
        }
      );


   
      
    } catch (error) {
      console.error('Error fetching functionalities:', error);
    }
  };

  const handleAppChange = (e) => {


    
    setSelectedApp(e.target.value);
    setSelectedComponentId('');
    setFunctionalities([]);
    setElementPermissions({});
  };

  const handleComponentChange = (e) => {
    setSelectedComponentId(e);
    
  };

  const handleOptionChange = (functionalityId, elementId, permission) => {
    setElementPermissions((prev) => ({
      ...prev,
      [functionalityId]: {
        ...prev[functionalityId],
        [elementId]: permission,
      },
    }));

   
  };

  const handleUpdatePermissions1 =(fid) => {
    




    try {
      
      const form4 = new FormData();
      
      


    
        form4.append("flag","get_user_functionality")
      
        form4.append("functionality_id",fid)

        
        
      

  
   

      let apitoken = localStorage.getItem("api_token");
      
      setShowModal(true);
      setSelectedFunctionId(fid);   
      setApiResults({});
      sendRequest(
        {
          url: `${process.env.REACT_APP_BASE_URL}/permission/data`,
          method: "POST",
          data:form4,
          headers: {
            
            
           
            'Content-Type': 'application/json',
            "api-token": apitoken,
          },
  
      
         
        },
        (response) => {
          console.log(response);
          
          //setUserNumber(response.users);
        
          
         // console.log(userNumber);
          
          
          

          
    setModalInfo({
      title: 'Confirm Update',
      message:   response.message ? ' No user will be affected by this update , Do you want to continue ?':response.users.length+' user(s) will be affected by this update , Do you want to continue ?',
      functionalityId: fid,
    });
          
         
          
         setUserNumber({});
    
    
          
         
        }


      );
      //alert('Permissions updated successfully!');
    } catch (error) {
      console.error('Error updating permissions:', error);
      //alert('Failed to update permissions.');
    }



  






    
  };

  const handleUpdatePermissions =  (fid) => {
    try {
      setShowModal(false);
     
      
      const form3 = new FormData();
      
      const filteredFunctionalities = functionalities.filter(
        func => func.functionality_id === fid
      );
 
      const payload = filteredFunctionalities.map((func) => ({
        flag: 'save_functionality',
        status: 'A',
        component_id: selectedComponentId,
        element_permissions: Object.entries(elementPermissions[func.functionality_id]).map(
          ([elementId, permission]) => ({
            element_id: elementId,
            permission,
          })
        ),
        function_name: func.function_name,

        
   
      }));


      payload.forEach(func => {
        form3.append("flag","save_functionality")
        form3.append("status","A")
        form3.append("component_id",selectedComponentId)
        form3.append("element_permissions", JSON.stringify(func.element_permissions));
        form3.append("function_name",func.function_name)

        
        
      });

  
   

      let apitoken = localStorage.getItem("api_token");
      
     
      
      sendRequest(
        {
          url: `${process.env.REACT_APP_BASE_URL}/permission/data`,
          method: "POST",
          data:form3,
          headers: {
            
            
           
            'Content-Type': 'application/json',
            "api-token": apitoken,
          },
  
      
         
        },
        (response) => {
          console.log(response);
          
         
        }
      );
     // alert('Permissions updated successfully!');
    } catch (error) {
      console.error('Error updating permissions:', error);
      //alert('Failed to update permissions.');
    }
    setSelectedFunctionId('');

   
  };

  const cancelUpdatePermissions = () => {
    setShowModal(false);
  };



  const handleFunctionalityButtonClick = (functionalityId) => {
    // Trigger your API here
    console.log(`API triggered for functionality with id: ${functionalityId}`);


    try {
      
      const form4 = new FormData();
      
      


    
        form4.append("flag","get_user_functionality")
      
        form4.append("functionality_id",functionalityId)

        
        
      

  
   

      let apitoken = localStorage.getItem("api_token");
      
     
      setisSpin(true);
      sendRequest(
        {
          url: `${process.env.REACT_APP_BASE_URL}/permission/data`,
          method: "POST",
          data:form4,
          headers: {
            
            
           
            'Content-Type': 'application/json',
            "api-token": apitoken,
          },
  
      
         
        },
        (response) => {
          console.log(response);
          const t = {};
          setApiResults(Array.isArray(response.users) ? response.users : [response.users]);
          
          console.log(apiResults);
          setShowResultsModal(true);
          setUserCount(response.users.length);
    
          
         
        }
      );
      //alert('Permissions updated successfully!');
    } catch (error) {
      console.error('Error updating permissions:', error);
      //alert('Failed to update permissions.');
    }
    setSelectedFunctionId('');

    setisSpin(false);
  
  };


  const divStyle = {

    
    color:'white'
    
  
    
    
    
  };

  const t={
    width:'100%'
  }
  

  return (
    
    <div className="container">
        

{isSpin ? (
          <div className="preloader">
            <img className="w30" src={loader} />
          </div>
        ) : (
          ""
        )}
        <Commonheader pagetitle={"Element Permission"} props={props} />
        <br />
        <br />
      <div className="dropdowns-row">
        <div className="dropdown">
          <label htmlFor="app">App:</label>
          <select id="app" onChange={handleAppChange} value={selectedApp}>
            <option value="">Select App</option>
            <option value="Collection_UI">Collection_UI</option>
          </select>
        </div>

        <div className="dropdown">
          <label htmlFor="component">Component:</label>
       


<Select  style={t}
                    showSearch

                    onChange={handleComponentChange}
                   
                    disabled={!Object.keys(components).length}
                  
                    placeholder="Select Component               "
                    optionFilterProp="children"
                    filterOption={(input, option) =>
                      (option && option.label
                        ? option.label.toLowerCase()
                        : ""
                      ).includes(input.toLowerCase())
                    }
                    filterSort={(optionA, optionB) =>
                      (optionA && optionA.label
                        ? optionA.label.toLowerCase()
                        : ""
                      ).localeCompare(
                        optionB && optionB.label
                          ? optionB.label.toLowerCase()
                          : ""
                      )
                    }
                    options={
                        components &&
                     components.map((key) => {
                        return {
                          label: key.component_name,
                          value: key.component_id,
                        };
                      })
                    }
                  />
        </div>
      </div>

      {selectedApp && selectedComponentId && functionalities.length > 0 && (
        <div className="functionalities">
          {functionalities.map((functionality) => (
            <div className="jumbotron" key={functionality.functionality_id}>
              <div
                className="jumbotron-header"
                onClick={() =>
                  setFunctionalities((prev) =>
                    prev.map((func) =>
                      func.functionality_id === functionality.functionality_id ? { ...func, isOpen: !func.isOpen } : func
                    )
                  )
                }
              >
                <h3 style={divStyle}>{functionality.function_name}</h3>
                 
                 <button
                      className="api-button"
                      onClick={(e) => { e.stopPropagation(); handleFunctionalityButtonClick(functionality.functionality_id)}}
                    >
                      Users
                    </button>
              </div>
              {functionality.isOpen && (
                <div className="jumbotron-content">
                  {functionality.element_permission.map((element) => (
                    <div className="element" key={element.element_id}>
                      <p>Element: {element.element_name}</p>
                      <div className="options">
                        
                        <div className="radio-buttons">
                          <div className='t'>
                            <input
                              type="radio"
                              id={`enable-${element.element_id}`}
                              value="E"
                              checked={
                                elementPermissions[functionality.functionality_id] &&
                                elementPermissions[functionality.functionality_id][element.element_id] === 'E'
                              }
                              onChange={() =>
                                handleOptionChange(functionality.functionality_id, element.element_id, 'E')
                              }
                            />
                            <label htmlFor={`enable-${element.element_id}`}>&nbsp;Enable</label>
                          </div>
                          <div className='t'>
                            <input
                              type="radio"
                              id={`disable-${element.element_id}`}
                              value="D"
                              checked={
                                elementPermissions[functionality.functionality_id] &&
                                elementPermissions[functionality.functionality_id][element.element_id] === 'D'
                              }
                              onChange={() =>
                                handleOptionChange(functionality.functionality_id, element.element_id, 'D')
                              }
                            />
                            <label htmlFor={`disable-${element.element_id}`}>&nbsp;Disable</label>
                          </div>
                          <div className='t'>
                            <input
                              type="radio"
                              id={`hidden-${element.element_id}`}
                              value="H"
                              checked={
                                elementPermissions[functionality.functionality_id] &&
                                elementPermissions[functionality.functionality_id][element.element_id] === 'H'
                              }
                              onChange={() =>
                                handleOptionChange(functionality.functionality_id, element.element_id, 'H')
                              }
                            />
                            <label htmlFor={`hidden-${element.element_id}`}>&nbsp;Hide</label>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
<br />
{functionality.element_permission.length>0 &&   <div className='but'>    
<button  onClick={() =>handleUpdatePermissions1(functionality.functionality_id)}>
            Update 
          </button>
          </div> 
}
                </div>
              )}

            </div>
          ))}
          
        </div>
      )}

<ResultsModal
        show={showResultsModal}
        onHide={() => setShowResultsModal(false)}
        results={apiResults}
      />

<Modal show={showModal} onHide={cancelUpdatePermissions}>
<Modal.Header closeButton>
              <Modal.Title>{modalInfo ? modalInfo.title : 'Confirm Update'}</Modal.Title>
            </Modal.Header>
            <Modal.Body>{modalInfo ? modalInfo.message : ''}</Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={cancelUpdatePermissions}>
                No
              </Button>
              <Button variant="primary" onClick={() =>handleUpdatePermissions(selectedFunctionId)}>
                Yes
              </Button>
            </Modal.Footer>
      </Modal>

    </div>
  );
};

export default App;
