import React, { useState, useEffect } from "react";
import Commonheader from "./Commonheader";
import CryptoJS from "crypto-js";

function Decrypt_payload(props) {
  const [loading, setLoading] = useState(false);
  const [isErrOpen, setIsErrOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState();
  const [inputData, setInputData] = useState("");
  const [outputData, setOutputData] = useState("");

  const base64Key = process.env.REACT_APP_AES_SECRET_KEY;

  const decryptPayload = () => {
    setLoading(true);
    try {
      const key = CryptoJS.enc.Base64.parse(base64Key);
      const ciphertext = CryptoJS.enc.Hex.parse(inputData);

      const iv = CryptoJS.lib.WordArray.create(ciphertext.words.slice(0, 4));
      const encryptedMessage = CryptoJS.lib.WordArray.create(ciphertext.words.slice(4));

      const decrypted = CryptoJS.AES.decrypt(
        { ciphertext: encryptedMessage },
        key,
        { iv: iv, padding: CryptoJS.pad.Pkcs7 }
      );

      const decryptedText = decrypted.toString(CryptoJS.enc.Utf8);
      if (!decryptedText) {
        throw new Error("Decryption failed or resulted in empty text");
      }

      try {
        const jsonData = JSON.parse(decryptedText);
        const formattedJson = JSON.stringify(jsonData, null, 2);
        setOutputData(formattedJson);
      } catch (jsonErr) {
        setOutputData(decryptedText);
      }
    } catch (err) {
      setIsErrOpen(true);
      setErrorMessage(`Decryption failed: ${err.message}`);
      setOutputData("");
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="container">
      <Commonheader pagetitle={"Decrypt Payload"} props={props} />
      {loading && (
        <div className="load-4">
          <div className="ring-1"></div>
        </div>
      )}
      <div
        className={
          isErrOpen
            ? "modal fade show d-block mymodal"
            : "modal fade bd-example-modal-sm"
        }
        tabIndex="-1"
        role="dialog"
        aria-labelledby="myLargeModalLabel"
        aria-hidden="true"
        id="confirm-box"
      >
        <div className="modal-dialog modal-lg">
          <div className="modal-content cdb-confirm-box col-sm-12">
            <button
              type="button"
              className="close text-right"
              data-dismiss="modal"
              aria-label="Close"
              onClick={() => {
                setIsErrOpen(false);
              }}
            >
              <span aria-hidden="true">&times;</span>
            </button>
            <p className="popuperrtext">{errorMessage}</p>
          </div>
        </div>
      </div>
      <div className="mb-3">
        <div>
          <textarea value={inputData} rows="4" onChange={(e)=>{setInputData(e.target.value)}} style={{width:'100%',marginTop:'10px',padding:'20px'}} placeholder="Enter Encrypted Text"></textarea>
        </div>
        <div className="d-flex justify-content-between">
          <div></div>
          <div>
            <button
              className="btn btn-primary mr-2"
              onClick={()=>{decryptPayload()}}
            >
              Decrypt
            </button>
          </div>
        </div>
      </div>
      {outputData && (
        <div>
          <h3>Decrypted Text</h3>
          <pre>{outputData}</pre>
        </div>
      )}
    </div>
  )
}
export default Decrypt_payload;