import React, { useState } from "react";
// import { QueryBuilderAntD } from "@react-querybuilder/antd";
// import { QueryBuilder, RuleGroupType } from "react-querybuilder";

const Test = () => {
  // const fields = [
  //   { name: "firstName", label: "First Name" },
  //   { name: "lastName", label: "Last Name" },
  //   { name: "a", label: "A" },
  //   { name: "b", label: "B" },
  //   { name: "c", label: "C" },
  // ];
  // // Function to add a new logical element to the form structure
  // const [query, setQuery] = useState({ combinator: "and", rules: [] });
  // {
  //   console.log("query->", query);
  // }
  // return (
  //   <QueryBuilderAntD>
  //     <QueryBuilder
  //       fields={fields}
  //       query={query}
  //       onQueryChange={(q) => setQuery(q)}
  //     />
  //   </QueryBuilderAntD>
  // );
};

export default Test;
