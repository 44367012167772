import React, { useState, useEffect, memo } from "react";
import Commonheader from "./Commonheader";
import useAxios from "../hooks/useAxios";
import moment from "moment";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

const Performance_view = memo(({ props }) => {
  const [loading, sendRequest] = useAxios();
  const [getui_data, setgetui_data] = useState([]);
  const [getui_filterdata, setgetui_filterdata] = useState([]);
  const [filterDate, setfilterDate] = useState();
  const [user_id, setuser_id] = useState();
  const [pageNumber, setpageNumber] = useState(1);
  const [isErrOpen, setisErrOpen] = useState(false);
  const [apiErrorMessage, setApiErrorMessage] = useState("");
  const [apiDetailData, setApiDetailData] = useState({});
  const [payloadList, setpayloadList] = useState({});
  const [payloadListAPI, setpayloadListAPI] = useState({});
  const [payloadDefaultList, setpayloadDefaultList] = useState({});
  const [getsession_data, setgetsession_data] = useState();

  useEffect(() => {
    console.log("Performance_view rendering");

    if (getui_filterdata) {
      loadMoreData();
    }
  }, [getui_filterdata]);

  const getsessiongroup = () => {
    let apitoken = localStorage.getItem("api_token");
    sendRequest(
      {
        url: `${process.env.REACT_APP_BASE_URL}/payload/get/frontend_req_payload`,
        method: "POST",
        data: JSON.stringify([
          ["created_at", ">", moment(filterDate).unix()],
          ["session_id", "group", ""],
        ]),
        headers: {
          "Content-Type": "application/json",
          "api-token": apitoken,
        },
      },
      (response) => {
        const convertedArray = Object.values(response).map((item) => ({
          user: item.user_id,
          session_id: item.session_id,
          created_at: item.created_at,
        }));
        setgetsession_data(convertedArray);

        // console.log("item-->", parsed_data);
        // setpayloadList(parsed_data);
        // const sortedData = [...parsed_data].sort(
        //   (a, b) => b.added_at - a.added_at
        // );
        // setgetui_filterdata(sortedData);
        // setpayloadDefaultList(sortedData);
        // setpageNumber(1);
        // setgetui_data([]);
      }
    );
  };
  const getuidata = (val) => {
    let apitoken = localStorage.getItem("api_token");

    sendRequest(
      {
        url: `${process.env.REACT_APP_BASE_URL}/payload/get/frontend_req_payload`,
        method: "POST",
        data: JSON.stringify([["session_id", "==", val]]),
        headers: {
          "Content-Type": "application/json",
          "api-token": apitoken,
        },
      },
      (response) => {
        console.log("res-->", response);
        // const response_data = [];
        // for (let item in response) {
        //   //console.log("item.Duration", response[item].Duration);
        //   response_data.push({
        //     ...response[item],
        //     key: item,
        //   });
        // }
        //setpayloadList(response_data);
        const parsed_data = Object.values(response).reduce(
          (result, current) => {
            if (
              current.data_write_for === "UI" &&
              (current.component_id !== "" || current.component_id !== null)
            ) {
              result.push(current);
            }
            return result;
          },
          []
        );

        const parsed_dataapi = Object.values(response).reduce(
          (result, current) => {
            if (
              current.data_write_for === "API" &&
              (current.component_id !== "" || current.component_id !== null)
            ) {
              result.push(current);
            }
            return result;
          },
          []
        );
        setpayloadListAPI(parsed_dataapi);
        console.log("item-->", parsed_data);
        setpayloadList(parsed_data);
        setgetui_data(parsed_data);
        const sortedData = [...parsed_data].sort(
          (a, b) => b.added_at - a.added_at
        );
        setgetui_filterdata(sortedData);
        setpayloadDefaultList(sortedData);
        setpageNumber(1);
        setgetui_data([]);
        // setTimeout(() => {
        //   get_uidata();
        // }, 1000);
      }
    );
  };

  const get_apidata = (compid) => {
    if (!apiDetailData[compid]) {
      console.log("filteredData->", payloadListAPI);
      const filteredData = payloadListAPI.filter(
        (item) => item.component_id === compid
      );
      setApiDetailData((prevData) => ({
        ...prevData,
        [compid]: filteredData,
      }));
    }
  };

  const loadMoreData = () => {
    let chunkSize = 20;
    const startIdx = (pageNumber - 1) * chunkSize;
    const endIdx = startIdx + chunkSize;
    const newDataChunk = getui_filterdata.slice(startIdx, endIdx);

    setgetui_data((prevData) => {
      if (!Array.isArray(prevData)) {
        prevData = [];
      }

      const newDataWithKeys = newDataChunk.map((item) => ({
        ...item,
        key: `${item.component_id}_${Math.random()}`,
      }));

      return [...prevData, ...newDataWithKeys];
    });
    setpageNumber((prevPageNo) => prevPageNo + 1);
  };

  const openPopup = (response) => {
    setApiErrorMessage(response);
    setisErrOpen(true);
  };

  const filterdata = (filterval) => {
    if (filterval.length > 0) {
      // const filteredData = payloadList.filter(
      //   (item) => item.component_name == filterval.toString()
      // );

      const filteredData = payloadList.filter(
        (item) =>
          item.component_name &&
          item.component_name.toLowerCase().includes(filterval.toLowerCase())
      );

      console.log("filteredData->", filteredData);
      setgetui_data(filteredData);
    } else {
      setgetui_filterdata(payloadDefaultList);
      setpageNumber(1);
      loadMoreData();
    }
  };
  const get_uidata = () => {
    setgetui_data();
    // const filteredData = payloadList.filter(
    //   (item) => item.data_write_for === "UI"
    // );
    // setgetui_data((prevData) => ({
    //   ...prevData,
    //   [sid]: filteredData,
    // }));

    setgetui_data((prevData) => {
      if (!Array.isArray(prevData)) {
        prevData = [];
      }

      const newDataWithKeys = payloadList.map((item) => ({
        ...item,
        key: `${item.component_id}_${Math.random()}`,
      }));

      return [...prevData, ...newDataWithKeys];
    });
  };
  console.log("getui_data->", payloadList);
  return (
    <div className="container">
      <Commonheader pagetitle={"Performance"} props={props} />
      {loading && (
        <div className="load-4">
          <div className="ring-1"></div>
        </div>
      )}
      <div className="mt-4">
        <div className="row">
          <div className="col-sm-12">
            <div className="float-left">
              <input
                type="datetime-local"
                onChange={(e) => setfilterDate(e.target.value)}
                className="form-control"
                placeholder="Start Date"
              />
            </div>
            {/* <div className="float-left ml-1">
              <input
                type="text"
                onChange={(e) => setuser_id(e.target.value)}
                className="form-control"
                placeholder="user id"
              />
            </div> */}
            <button
              className="btn btn-primary ml-2"
              disabled={!filterDate}
              onClick={() => getsessiongroup("search")}
            >
              Search
            </button>
          </div>
        </div>
        {getui_data.length > 0 && (
          <div className="row mt-2">
            <div className="col-md-3">
              <input
                className="form-control"
                onChange={(e) => filterdata(e.target.value)}
                placeholder="Enter Component Name"
              />
            </div>
          </div>
        )}
        <div className="mt-4">
          <div id="accordion">
            {getsession_data &&
              getsession_data.map((session_data, index) => (
                <div class="card mt-2">
                  <div
                    class="card-header"
                    id="headingOne"
                    data-toggle="collapse"
                    data-target={"#" + index}
                    onClick={() => getuidata(session_data.session_id)}
                  >
                    <div className="row">
                      <div className="col-sm-2">
                        <strong>Session Id</strong>
                        <br />
                        {session_data.session_id}
                      </div>
                      <div className="col-sm-3">
                        <strong>User</strong>
                        <br />
                        {session_data.user}
                      </div>
                      <div className="col-sm-2">
                        <strong>Created At</strong>
                        <br />
                        {new Date(session_data.created_at * 1000).toLocaleString()}
                      </div>
                    </div>
                  </div>

                  <div
                    id={index}
                    class="collapse"
                    aria-labelledby="headingOne"
                    data-parent="#accordion"
                  >
                    <div class="card-body">
                      {/* /////////////////////////////////// */}
                      <div id={"uiaccordion" + index} className="mt-2">
                        {getui_data &&
                          getui_data.map(
                            (uidata, index1) =>
                              uidata.component_id && (
                                <div className="card mb-2" key={index1}>
                                  <div
                                    className="card-header"
                                    data-toggle="collapse"
                                    data-target={"#sub" + index1 + "_" + index}
                                    onClick={() =>
                                      get_apidata(uidata.component_id)
                                    }
                                  >
                                    <div className="row">
                                      <div className="col-sm-2">
                                        <strong>Log Id</strong>
                                        <br />
                                        {uidata.component_id}
                                      </div>
                                      <div className="col-sm-3">
                                        <strong>Comp. Name</strong>
                                        <br />
                                        {uidata.component_name}
                                      </div>
                                      <div className="col-sm-2">
                                        <strong>Created At</strong>
                                        <br />
                                        {new Date(uidata.created_at * 1000).toLocaleString()}
                                      </div>
                                      {/* <div className="col-sm-2">
                          <strong>Difference</strong>
                          <br />
                          {uidata.Duration}
                        </div> */}
                                    </div>
                                  </div>
                                  <div
                                    id={"sub" + index1 + "_" + index}
                                    className="collapse"
                                    data-parent={"#uiaccordion" + index}
                                  >
                                    <div className="card-body">
                                      <table className="table">
                                        <thead>
                                          <tr>
                                            <th>API</th>
                                            <th>Request</th>
                                            <th>Response</th>
                                            <th>start</th>
                                            <th>end</th>
                                            <th>Difference</th>
                                            <th>IP</th>
                                            <th>Status</th>
                                          </tr>
                                        </thead>
                                        <tbody>
                                          {apiDetailData[uidata.component_id] &&
                                            apiDetailData[
                                              uidata.component_id
                                            ].map((apidata, indexkey) => (
                                              <APIData
                                                key={apidata.key}
                                                apidata={apidata.apiId}
                                                openPopup={openPopup}
                                                url={apidata.url}
                                                ip_address={apidata.ip_address}
                                                requestdata={apidata.data}
                                              />
                                            ))}
                                        </tbody>
                                      </table>
                                      <div
                                        className={
                                          isErrOpen
                                            ? "modal fade show d-block mymodal"
                                            : "modal fade bd-example-modal-sm"
                                        }
                                        tabIndex="-1"
                                        role="dialog"
                                        aria-labelledby="myLargeModalLabel"
                                        aria-hidden="true"
                                        id="confirm-box"
                                      >
                                        <div className="modal-dialog modal-lg">
                                          <div className="modal-content cdb-confirm-box col-sm-12">
                                            <button
                                              type="button"
                                              className="close text-right"
                                              data-dismiss="modal"
                                              aria-label="Close"
                                              onClick={() => {
                                                setApiErrorMessage("");
                                                setisErrOpen(false);
                                              }}
                                            >
                                              <span aria-hidden="true">
                                                &times;
                                              </span>
                                            </button>
                                            <p className="popuperrtext">
                                              {apiErrorMessage}
                                            </p>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              )
                          )}
                        {/* <div className="text-center">
                          {getui_data &&
                            getui_data.length !==
                              Object.keys(getui_filterdata).filter((key) =>
                                getui_filterdata[key].hasOwnProperty(
                                  "component_id"
                                )
                              ).length && (
                              <button
                                className="m-2 btn btn-primary"
                                onClick={() => loadMoreData()}
                              >
                                Load More
                              </button>
                            )}
                        </div> */}
                      </div>
                      {/* /////////////////////////////////// */}
                    </div>
                  </div>
                </div>
              ))}
          </div>
        </div>
      </div>
    </div>
  );
});

const APIData = memo(({ apidata, openPopup, url, ip_address, requestdata }) => {
  console.log("apidata->", apidata);
  const [loading, sendRequest] = useAxios();
  const apitoken = localStorage.getItem("api_token");
  const [apidetail, setapidetail] = useState();
  const [isFetching, setIsFetching] = useState(false);
  const [hasFetched, setHasFetched] = useState(false);

  const api_detail = (api_id) => {
    try {
      console.log("Calling api_detail for api_id:", api_id);
      sendRequest(
        {
          url: `${process.env.REACT_APP_BASE_URL}/payload/get/backend_req_payload`,
          method: "POST",
          data: JSON.stringify([["apiId", "==", api_id]]),
          headers: {
            "Content-Type": "application/json",
            "api-token": apitoken,
          },
        },
        (response) => {
          const parsed_data = Object.values(response).filter(
            (item) => !item.Duration
          );

          console.log("apidetail==>", parsed_data);
          setapidetail(parsed_data);
          setIsFetching(false);
          setHasFetched(true);
        }
      );
    } catch (e) {
      console.log(JSON.stringify(e));
      setIsFetching(false);
      setHasFetched(true);
    }
  };

  useEffect(() => {
    if (!hasFetched && !isFetching) {
      setIsFetching(true);
      api_detail(apidata);
    }
  }, [apidata, hasFetched, isFetching]);

  return (
    <>
      {apidetail && apidetail.length > 0 && (
        <>
          <tr>
            <td className="w400">{url}</td>
            <td>
              {requestdata !== "" && requestdata !== null ? (
                <span
                  className="btn btn-primary"
                  onClick={() =>
                    openPopup(
                      requestdata !== "" && requestdata !== null
                        ? requestdata
                        : ""
                    )
                  }
                >
                  view
                </span>
              ) : (
                ""
              )}
            </td>
            <td>
              <span
                className="btn btn-primary"
                onClick={() => openPopup(JSON.stringify(apidetail[0].response))}
              >
                view
              </span>
            </td>{" "}
            <td>{new Date(apidetail[0].start * 1000).toLocaleString()}</td>
            <td>{new Date(apidetail[0].end * 1000).toLocaleString()}</td>
            <td>{apidetail[0].latency}</td>
            <td>{ip_address}</td>
            <td>{apidetail[0].status_code}</td>
          </tr>
        </>
      )}
    </>
  );
});

export default Performance_view;
